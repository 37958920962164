import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from 'classnames';
import { HeaderUserIcon, EditDarkIcon as EditIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { UserSettingsIcon } from "@netapp/bxp-style/react-icons/Action";
import styles from "./../MainHeader.module.scss";
import userMenuStyles from "./UserMenu.module.scss";
import SlidingWidget from "./SlidingWidget"
import { StyledLink, Button, Span, Toggle, Tag, Tooltip, FormFieldInputNew } from "@netapp/bxp-design-system-react";
import buttons from "@netapp/bxp-design-system-react/styles/buttons.module.scss";


const StaticDetails = ({
    children,
    label,
    value,
    isEditable=false,
    handleEdit,
}) => {
    return (
        <div className={userMenuStyles["detail"]}>
            <div className={classNames(styles["static-details"])}>
                <div>
                    {children}
                    <div className={classNames(userMenuStyles["details-value"], styles["info"])}>
                        {value}
                    </div>
                    <div className={userMenuStyles["details-label"]}>
                        {label}
                    </div>
                </div>
                {isEditable  && (
                     <button className={classNames(styles['edit-btn'])}
                         onClick={
                                (e) => {
                                    e.stopPropagation();
                                    handleEdit(true);
                                }
                         }>
                     <EditIcon />
                 </button>
                )}
            </div>
        </div>
    );
};


const DarkTheme = () => {
    const dispatch = useDispatch();
    const isActive = useSelector(state => !!state.features.active["Platform.BlueXP/DarkTheme"]);
    return <div className={userMenuStyles["detail"]}>
        <div style={{ marginBottom: 8 }}>
            <Span style={{ marginRight: 8 }}>Dark Theme</Span>
            <Tag color="chart-9">Beta
                <Tooltip>
                    {isActive ? <>
                        <div>There is a crack, a crack in everything.</div>
                        <div>That's how the light gets in.</div>
                    </> : <>
                            <div>You want it darker?</div>
                            <div>We kill the flame.</div>
                        </>}
                </Tooltip>
            </Tag>
        </div>
        <Toggle value={isActive} ariaLabel="Dark theme" toggle={() => {
            dispatch({
                type: "FEATURES:TOGGLE-FEATURE",
                payload: {
                    feature: "Platform.BlueXP/DarkTheme"
                }
            })
        }}/>
    </div>
}

const UserNameEditForm = ({ handleNameChange, handleCancel, handleSubmit, userName, error, isLoading }) => (
    <div className={styles["edit-name-form"]}>
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit();}}>
            <div className={classNames(styles["content"])}>
                <div className={styles["input-restrict"]}>
                    <FormFieldInputNew
                        type="text"
                        name="Name"
                        label="Name"
                        value={userName}
                        onChange={event => handleNameChange(event)}
                        errorMessage={error}
                        showError={!!error}
                    />
                </div>
                <div className={styles["button-wrapper"]}>
                    <Button submit type="text" isSubmitting={isLoading}>Save</Button>
                    <button onClick={(e) => {
                                e.stopPropagation();
                                handleCancel(false)
                            }} 
                        className={classNames(buttons["text-secondary"], styles["button-space"])}>
                                Cancel
                    </button>
                </div>
            </div>
        </form>
    </div>
);

export default () => {
    const dispatch = useDispatch();
    const { name, email, company, isNetAppInternal, isFederatedUser, getUserNamePending, getUserNameShowError } = useSelector(state => state.auth);
    const isActive = useSelector(state => state.menu.activeWidget);
    const [editName, setEditName] = useState(false);
    const [userName, setUserName] = useState(name);
    const [error, setError] = useState('');

    const isDarkSite = process.env.REACT_APP_IS_DARK === 'true';

    useEffect(() =>{
        if(!isActive || (!getUserNamePending && !getUserNameShowError)) {
            setEditName(false);
            setUserName(name);
        } 
    }, [isActive, getUserNamePending, getUserNameShowError]);

    useEffect(() =>{
        if (getUserNameShowError) {
            setError('Failed to update the name');
        }
    }, [getUserNameShowError]);


    const handleNameChange  = (event) => {
        setUserName(event.target.value);
    }

    const handleShowHideUserName = (isEditUserName) => { 
        setEditName(isEditUserName);
        setError('');
        if (!isEditUserName) {
            setUserName(name);
        }
    }

    const handleNameSubmit = async () => {
        if(userName.length < 1){
            setError('Name is required');
            return;
        }

        if(userName.length > 255){
            setError('Name cannot be more than 255 characters');
            return;
        }

        if (userName !== name) {
            dispatch({
                type: "USER:UPDATE-USERNAME",
                payload: {
                    userName
                }
            })
        }  
    }

    return <SlidingWidget
        widgetKey="user"
        menuTitle="User Settings"
        MenuIcon={UserSettingsIcon}
        MenuActionButton={() => {
            return <Button onClick={() => dispatch({
                type: "AUTH:LOGOUT"
            })}>Logout</Button>
        }}
        isActive={isActive}
        triggerClassName={styles['widget-button']}
        triggerActiveClassName={[styles['widget-button-active']]}
        triggerChildren={<HeaderUserIcon size={24} display="block"/>}
    >
            <div className={userMenuStyles["content"]}>
            {
            
                editName ? (
                    <UserNameEditForm
                        handleNameChange={handleNameChange}
                        handleCancel={handleShowHideUserName}
                        handleSubmit={handleNameSubmit}
                        userName={userName}
                        error={error}
                        isLoading={getUserNamePending}
                    />
                ) : (
                    <StaticDetails
                        handleEdit={handleShowHideUserName}
                        isEditable={!isFederatedUser && !isDarkSite}
                        label="Name" 
                        value={name}
                    />
                )
            }

                <StaticDetails label="Email" value={email}/>
                <StaticDetails label="Company" value={company}>
                    {isNetAppInternal && <StyledLink to="/netapp-internal" variant="text" style={{ position: "absolute", right: 40, marginTop: 15, zIndex: 1 }} onClick={() => {
                        dispatch({
                            type: "MENU:CLOSE-WIDGET"
                        })
                    }}>NetApp Internal</StyledLink>}
                </StaticDetails>
                <DarkTheme/>
            </div>
    </SlidingWidget>
};

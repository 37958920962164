import classNames from "classnames";
import useIframe from "utils/iframe/useIframe";
import externals from "utils/externals";
import styles from "../../ServiceIframe.module.scss";

/**
 * V1 is deprecated, dont update it, any new integrations must be on V2
 */
export const RenderV1 = ({ version, iframeKey, isThirdParty, trackKey, setWizardOpen, isWizardOpen }) => {
    const { iframeRef, src } = useIframe({ iframeUrl: externals.serviceIframes[iframeKey], isThirdParty, trackKey, setWizardOpen })

    return <iframe title="service" src={src} ref={iframeRef} className={classNames(styles['base'], { [styles['sub-tab']]: version === "childIframe/v1" && !isWizardOpen })}/>
};
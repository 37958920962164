import { init } from '@module-federation/runtime';
import packageJson from '../../../../../../cmui/package.json';

// Init all the MFEs
const initMfes = (remotes) => { 
    return init({
        name: "cmui",
        remotes,
        shared: {
          react: {
            requiredVersion: packageJson.dependencies.react
          },
          "react-dom": {
            requiredVersion: packageJson.dependencies["react-dom"]
          },
          "@netapp/bxp-design-system-react": {
            requiredVersion: packageJson.dependencies["@netapp/bxp-design-system-react"]
          }
        }
      });
}

export default initMfes;
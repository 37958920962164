import React from 'react';
import { createRoot } from 'react-dom/client';
import "./modules/app/configureYup"
import App from './App';
import { Provider } from 'react-redux';
import { Route, Router, Switch, NavLink, Link } from 'react-router-dom';
import auth0 from "auth0-js";
import store, { history } from './store';
import * as serviceWorker from './serviceWorker';
import "./index.module.scss";
import "@netapp/bxp-style/css/reset.css";
import "@netapp/bxp-design-system-react/dist/index.css";
import { initModalTargetListener, setInnerTabLinkProvider, ThemeProvider, setExternalLinkTracker } from "@netapp/bxp-design-system-react";
import { trackExternalLink } from "./utils/mrTracker";
import { getItemAnon } from "./utils/localstorage";

setInnerTabLinkProvider({ Link, NavLink });
setExternalLinkTracker({ trackExternalLink: trackExternalLink });

window.auth0 = auth0;

const isDarkTheme = getItemAnon({itemKey: "feature.Platform.BlueXP/DarkTheme"}) || false;

if (window.location.pathname.startsWith("/api-doc") && window.location.pathname.indexOf("index.html") === -1) {
    window.location.href = `${window.location.origin}/api-doc/index.html?path=${window.location.pathname}`
} else {
    store.dispatch({
        type: "APP:BOOT"
    });

    store.dispatch({
        type: "APP:LOAD-SERVICES"
    });

    history.listen(() => {
        window.scrollTo(0, 0);
    });

    initModalTargetListener();

    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <Router history={history}>
                    <ThemeProvider theme={isDarkTheme ? "dark" : "light"} isRoot={true}>
                        <Switch>
                            <Route path="/api-doc" render={() => window.location.href = `${window.location.origin}/api-doc/index.html?path=${window.location.pathname}`}/>
                            <Route path="/" render={() => <App/>}/>
                        </Switch>
                    </ThemeProvider>
                </Router>
            </Provider>
        </React.StrictMode>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();

}

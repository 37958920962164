import awsRegions from "../aws/regions";

import styles from "./Styles.module.scss";
import { AzureIcon, GcpIcon, AwsIcon, AwsCircleIcon, AzureCircleIcon, GcpCircleIcon, OnpremCircleIcon, OnpremIcon } from "@netapp/bxp-design-system-react/icons/custom";

const allRegions = {
    aws: awsRegions
}

export const regionLabel = (provider, regionOrLocation) => {
    return allRegions[provider]?.[regionOrLocation] || regionOrLocation;
}

export const providersMap = {
    aws: { label: "AWS", longLabel: "Amazon Web Services", icon: AwsIcon, circleIcon: AwsCircleIcon, tableIconClassName: styles['aws-icon'] },
    azure: { label: "Azure", longLabel: "Microsoft Azure", icon: AzureIcon, circleIcon: AzureCircleIcon, tableIconClassName: styles['azure-icon'] },
    gcp: { label: "Google Cloud", longLabel: "Google Cloud Platform", icon: GcpIcon, circleIcon: GcpCircleIcon, tableIconClassName: styles['gcp-icon'] },
    "on-prem": { label: "On-Premises", longLabel: "On-Premises", icon: OnpremIcon, circleIcon: OnpremCircleIcon, tableIconClassName: styles['on-prem-icon'] }
};

export const providerAddWEURLPath = (provider, featuresByKey) => {
    const cvoAwsKey = "NetApp.OntapCVO/new-create-cvo-AWS"; 
    const cvoGcpKey = "NetApp.OntapCVO/new-create-cvo-GCP"; 
    const cvoAzureKey = "NetApp.OntapCVO/new-create-cvo-AZURE"; 
    const urlPath = {
        cvo: {
            aws: '/add-new-working-environment/cvo/aws',
            gcp: '/add-new-working-environment/cvo/gcp',
            azure: '/add-new-working-environment/cvo/azure',
            angular: '/add-new-working-environment-wizard'
        }
    };
    
    if(provider === 'aws' && featuresByKey[cvoAwsKey]?.active) {
        return urlPath.cvo.aws
    } else if (provider === 'gcp' && featuresByKey[cvoGcpKey]?.active) {
        return urlPath.cvo.gcp
    } else if (provider === 'azure' && featuresByKey[cvoAzureKey]?.active) {
        return urlPath.cvo.azure
    } else {
        return urlPath.cvo.angular
    }
}

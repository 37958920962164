import { useDispatch } from "react-redux";
import styles from "../ServiceIframe.module.scss";

const GlobalSearchMask = () => {
    const dispatch = useDispatch();

    return <div className={styles["mask"]} onClick={() => {
        dispatch({type: "GLOBAL-SEARCH/CLOSE"})
    }}/>
}

export default GlobalSearchMask;
import { useEffect, useState } from "react";
import { loadRemote } from '@module-federation/runtime';

function useDynamicImport({ module, scope }) {
    const [component, setComponent] = useState();
    const [notification, setNotification] = useState();
    console.info(`RenderMfe: ${scope}/${module}`);

    useEffect(() => {
      if (!module || !scope) return;
  
      const loadComponent = async () => {
        try {
          const Component = await loadRemote(`${scope}/${module}`);
          console.info('Loading the MFE component')
          setComponent(() => Component.ModuleFederatedRemoteComponent);
        } catch (error) {
          console.error(error);
          setNotification(`Failed to load ${scope}/${module}.`);
        }
      };
  
      loadComponent();
    }, [module, scope]);
  
    return [component, notification];
}

export default useDynamicImport;
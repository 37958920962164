import  { useEffect } from "react"
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";
import externals from "utils/externals";
import GlobalSearchMask from "../GlobalSearchMask"
import { RenderV2 } from "./RenderV2";
import { RenderV1 } from "./RenderV1";

const RenderIframe = ({ currentService, subTabLabel, isThirdParty, isWizardOpen = false, setWizardOpen = _.noop, iframeKey: _iframeKey, version }) => {
    const isGlobalSearchOpen = useSelector(state => state.globalSearch?.isOpen);
    const location = useLocation();

    let iframeKey = null;
    if(_iframeKey) { 
        iframeKey = _iframeKey;
    } else if(currentService.uiIntegration?.stageIntegrations) {
        const servicePath  = _.replace(location.pathname, currentService.service.to, "");
        // checks if any sub tab path exists
        let rootIntegration = _.find(currentService.uiIntegration.stageIntegrations, { path: servicePath && servicePath.trim().length === 0 ? "/": servicePath});
        if(!rootIntegration) {
            // Navigates to the default path if not defined.
            rootIntegration = _.find(currentService.uiIntegration.stageIntegrations, { path: "/"});
        }
        if(rootIntegration) {
            iframeKey = rootIntegration.endpointId;
            version = rootIntegration.renderType;
        }
    } else {
        iframeKey = currentService.key;
    }

    const trackKey = subTabLabel ? `${currentService.key}.${subTabLabel}` : currentService.key;
    useEffect(() => {
        return () => {
            setWizardOpen(false);
        }
    }, [setWizardOpen]);

    if(!version) {
        version = "iframe/v1";
    }

    if (!externals.serviceIframes[iframeKey]) {
        return <div>
            Invalid Configuration -> No Path for {iframeKey}
        </div>
    }

    return <>
        {isGlobalSearchOpen && <GlobalSearchMask/>} {/* Need to add mask over iframe otherwise the app doesnt track clicks when global search is open */}
        {(version === "iframe/v2" || version === "childIframe/v2") && <RenderV2 currentService={currentService} iframeKey={iframeKey} isThirdParty={isThirdParty} trackKey={trackKey}/>}
        {(version === "iframe/v1" || version === "childIframe/v1") && <RenderV1 iframeKey={iframeKey} version={version} isThirdParty={isThirdParty} trackKey={trackKey} setWizardOpen={setWizardOpen} isWizardOpen={isWizardOpen}/>}
    </>
};

export default RenderIframe;
import SlidingWidget from "../../app/components/headerMenus/SlidingWidget";
import headerStyles from "../../app/components/MainHeader.module.scss";
import styles from "./NotificationMenu.module.scss";
import { HeaderNotificationsIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { BellIcon } from "@netapp/bxp-style/react-icons/Notification";
import React, {useEffect} from "react";
import classNames from "classnames";
import { useSelector,useDispatch } from "react-redux";
import NotificationsContextMenu from "./NotificationsContextMenu";
import NotificationsContent from "./NotificationsContent";
import NotificationsFooter from "./NotificationsFooter";
const wKey = "notifications"
const NotificationMenuButton = ({ buttonRef, onClick, onKeyDown, className, menuTitle }) => {
    const count = useSelector(state => state.uiNotifications.notifications.length);
    const highestPriority = useSelector(state => state.uiNotifications.highestPriority);

    return <button ref={buttonRef} onMouseDown={e => e.preventDefault()} onClick={onClick} onKeyDown={onKeyDown} className={classNames(styles["trigger"], className)} aria-label={menuTitle}>
        <HeaderNotificationsIcon display="block"/>
        {count > 0 && <div className={classNames(styles["counter"], styles[highestPriority])}>{count > 99 ? "99+" : count}</div>}
    </button>
}

export default React.memo(() => {
    const selectedAccount = useSelector(state => state.tenancy.selectedAccount);
    const notifications = useSelector(state => state.uiNotifications.notifications);
    const count = notifications.length
    const dispatch = useDispatch()
    useEffect(()=>{
        if(selectedAccount) {
            dispatch({
                type: "UI-NOTIFICATIONS:CONNECT"
            });
            dispatch({
                type: "UI-NOTIFICATIONS:FETCH-MESSAGES"
            });
        }
    },[dispatch, selectedAccount])



    return (
        <SlidingWidget
            className={styles["menu"]}
            widgetKey={wKey}
            menuTitle={`Notifications (${count})`}
            triggerClassName={headerStyles['widget-button']}
            triggerActiveClassName={[headerStyles['widget-button-active']]}
            MenuIcon={BellIcon}
            TriggerButton={NotificationMenuButton}
            HeaderContents={NotificationsContextMenu}
            MenuFooter={NotificationsFooter}>
            <NotificationsContent/>
        </SlidingWidget>
    );
});


import React, {  useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import { MainLoader } from "@netapp/bxp-design-system-react";
import externals from "utils/externals";
import useIframeV2 from "utils/iframe/useIframeV2";
import styles from "../../ServiceIframe.module.scss";

export const RenderV2 = ({ iframeKey, isThirdParty, trackKey, currentService }) => {
    const activeFeatures = useSelector(state => state.features.active);
    const subTabs = useMemo(() => {
        const featuresById = _.keyBy(currentService.uiIntegration?.features, "id");
        return _.filter(currentService.uiIntegration?.subTabs, subTab => {
            return subTab.featureId ? activeFeatures[subTab.featureId] : true
        }).map(subTab => {
            return {
                to: subTab.to,
                label: subTab.label,
                featureTargets: subTab.featureId ? featuresById[subTab.featureId]?.targets : undefined
            }
        });
    }, [activeFeatures, currentService])
    const { isInitialized, iframeRef, src } = useIframeV2({ iframeUrl: externals.serviceIframes[iframeKey], isThirdParty, trackKey, subTabs })

    return <>
        {!isInitialized && <MainLoader/>}
        <iframe title="service" src={src} ref={iframeRef} className={classNames(styles['base'])} style={{ opacity: !isInitialized ? 0 : undefined}}/>
    </>
}